<template>
	<div class="dataanalysis">
		<div class="my-title">
			<p>银行流水分析</p>
		</div>
		<div style="margin: 0 20px; padding-bottom: 30px">
			<div class="sy-card-header"><i></i>银行流水</div>
			<div style="padding: 0 10px 10px 0">
				<div>
					<el-dropdown @command="handleCommand" style="margin-right: 10px" placement="bottom-start">
						<el-button id="v-step-01" type="primary">
							下载导入模板<i class="el-icon-arrow-down el-icon--right"></i>
						</el-button>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="2">标准模板（贷方、借方发生额，金额两字段）</el-dropdown-item>
							<el-dropdown-item command="1">标准模板（交易金额，金额单个字段）</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
					<el-button id="v-step-22" type="primary" @click="submitForm('ruleForm', 'dialogVisible')">导入</el-button>

					<el-button id="v-step-33" type="primary" @click="getCalculateBankStatement">自动计算银行流水分析数据</el-button>
					<el-button type="primary" @click="clearData">清空银行流水数据</el-button>
				</div>
			</div>
			<div class="file-list">
				<p>银行流水分析计算参数</p>
				<el-form :model="ruleForm" status-icon ref="ruleForm" class="demo-ruleForm" label-width="90px">
					<div class="search-list" style="display: flex">
						<el-form-item id="v-step-11" label="客户名称:" :rules="[
							{
								required: true,
								message: '请输入客户名称',
							},
						]" prop="clientName">
							<el-autocomplete clearable suffix-icon="el-icon-arrow-down" style="width: 100%" class="inline-input"
								v-model="ruleForm.clientName" :fetch-suggestions="querySearch" placeholder="请输入客户名称"
								@select="handleSelect" @input="setValueNull"></el-autocomplete>
						</el-form-item>
						<el-form-item label="法人:" prop="legalPerson">
							<el-input placeholder="请输入法人" v-model="ruleForm.legalPerson"></el-input>
						</el-form-item>
						<el-form-item label="上一年度主营业务收入:" prop="mainBusinessIncomeLastYear" label-width="180px" :rules="[
							{
								pattern:
									/(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
								message: '请输入正确金额',
							},
						]">
							<div style="display: flex">
								<el-input placeholder="请输入主营业务收入" v-model="ruleForm.mainBusinessIncomeLastYear
									"></el-input>
								<div style="width: 50px; color: rgb(240, 2, 0)">
									(万元)
								</div>
							</div>
						</el-form-item>
					</div>
					<div class="search-list" style="display: flex">
						<div class="add-list">
							<el-form-item class="mb" label="股东:" v-for="(item, index) in ruleForm.shareholder" :key="'A' + index">
								<div style="display: flex; align-items: center">
									<el-input placeholder="请输入股东" v-model="item.value"></el-input>
									<i style="
											font-size: 30px;
											margin-left: 10px;
										" :class="[
											index == 0
												? 'el-icon-circle-plus-outline'
												: 'el-icon-remove-outline',
										]" @click="addFactor(index, 'shareholder')"></i>
								</div>
							</el-form-item>
						</div>
						<div class="add-list">
							<el-form-item class="mb" label="关联公司:" v-for="(item, index) in ruleForm.companyName" :key="'B' + index">
								<div style="display: flex; align-items: center">
									<el-input placeholder="请输入关联公司" v-model="item.value"></el-input>
									<i style="
											font-size: 30px;
											margin-left: 10px;
										" :class="[
											index == 0
												? 'el-icon-circle-plus-outline'
												: 'el-icon-remove-outline',
										]" @click="addFactor(index, 'companyName')"></i>
								</div>
							</el-form-item>
						</div>
						<div class="add-list">
							<el-form-item label-width="180px" class="mb" label="关键人名称:" v-for="(item, index) in ruleForm.personName"
								:key="'C' + index">
								<div style="display: flex; align-items: center">
									<el-input placeholder="请输入关键人名称" v-model="item.value"></el-input>
									<i style="
											font-size: 30px;
											margin-left: 10px;
										" :class="[
											index == 0
												? 'el-icon-circle-plus-outline'
												: 'el-icon-remove-outline',
										]" @click="addFactor(index, 'personName')"></i>
								</div>
							</el-form-item>
						</div>
					</div>
				</el-form>
			</div>
			<div style="padding: 0 10px 10px 0">
				<div>
					<span>交易银行：</span>
					<el-input style="width: 15%; margin: 0 10px" placeholder="请输入交易银行"
						v-model="ruleForm.transactionBank"></el-input>
					<el-button type="primary" @click="getBankFlowList">查询</el-button>

					<el-button type="primary" @click="() => {
							ruleForm.transactionBank = '';
							ruleForm.pageNo = 1;
							getBankFlowList();
						}
						">清空</el-button>
				</div>
			</div>
			<!-- table 列表 -->
			<el-table height="calc(100vh - 400px)" :data="billData" border style="width: 100%">
				<el-table-column align="left" prop="transactionDate" label="交易日期" width="100">
				</el-table-column>
				<el-table-column align="left" prop="accountName" label="对方户名">
				</el-table-column>
				<el-table-column align="left" prop="accountNo" label="对方账号">
				</el-table-column>
				<el-table-column align="left" prop="debitAmount" width="100" label="借方发生额">
				</el-table-column>
				<el-table-column align="left" width="100" prop="lenderAmount" label="贷方发生额">
				</el-table-column>
				<el-table-column align="left" prop="balanceAmount" width="100" label="余额">
				</el-table-column>
				<el-table-column align="left" prop="summary" label="摘要">
				</el-table-column>
				<el-table-column align="left" prop="remark" label="备注">
				</el-table-column><el-table-column align="left" prop="transactionBank" label="交易银行" width="120">
				</el-table-column>
				<el-table-column width="80" fixed="right" align="center" label="操作">
					<template slot-scope="scope">
						<p style="
								color: rgb(2, 167, 240);
								cursor: pointer;
								margin-right: 17px;
							" @click="delRowData(scope.row)">
							删除
						</p>
					</template>
				</el-table-column>
				<el-empty slot="empty" description="没有找到符合条件的数据"></el-empty>
			</el-table>
			<!-- 分页 -->
			<div class="fy">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="ruleForm.pageNo" :page-sizes="[5, 10, 20, 30, 50]" :page-size="10"
					layout="total, sizes, prev, pager, next, jumper" :total="ruleForm.total">
				</el-pagination>
			</div>

			<div style="position: relative" class="sy-card-header">
				<i></i>银行流水分析表
				<el-button type="primary" style="position: absolute; left: 150px" @click="exportBankFlowData">
					导出银行流水分析表
				</el-button>
			</div>
			<div class="content-box">
				<div>
					<p>流水开始年月 ：{{ calculateObj.startMonth }}</p>
					<p>流水统计月数 ：{{ calculateObj.monthNum }}</p>
					<p>
						合计总流入(元)<el-tooltip class="item" effect="dark" content="合计总流入：统计所有贷方金额的总和" placement="right">
							<i class="icon iconfont icon-wenhao"></i> </el-tooltip>：{{ calculateObj.totalIncome }}
					</p>
					<p>
						同名划入(元)<el-tooltip class="item" effect="dark" content="同名划入：统计贷方交易对手是借款人本人的流水的贷方金额总和，交易对手与客户名称同名"
							placement="right">
							<i class="icon iconfont icon-wenhao"></i> </el-tooltip>：{{ calculateObj.sameNameTransferIn }}
					</p>
					<p>
						关联或疑似关联流入(元)<el-tooltip class="item" effect="dark" placement="right">
							<div v-html="'关联或疑似关联流入：统计贷方交易对手是借款人法人、股东、关联公司的流水的贷方金额总和<br> 交易对手与法定代表人同名、交易对手与关键人的姓名同名、交易对手与股东同名、交易对手与关联公司同名'
								" slot="content"></div>
							<i class="icon iconfont icon-wenhao"></i>
						</el-tooltip>：{{ calculateObj.relatedFlowIn }}
					</p>
					<p>
						金融机构流入(元)<el-tooltip class="item" effect="dark" content="金融机构流入：贷方交易对手名称含银行、保理、融资租赁、小贷、担保、金融等字样的流水的贷方金额总和"
							placement="right">
							<i class="icon iconfont icon-wenhao"></i> </el-tooltip>：{{ calculateObj.financialOrganizationsFlowIn }}
					</p>
					<p>
						私转公流入(元)<el-tooltip class="item" effect="dark" content="私转公流入：贷方交易对手是个人的流水统计，首先排除公司关键字，然后判断字符数介于4个字符到8个字符"
							placement="right">
							<i class="icon iconfont icon-wenhao"></i> </el-tooltip>：{{ calculateObj.privateToPublicFlowIn }}
					</p>
					<p>
						经营性流入(元)<el-tooltip class="item" effect="dark"
							content="经营性流入 = 合计总流入 - 同名划入 - 关联或疑似关联流入 - 金融机构流入 - 私转公流入 + 借款人法人、股东贷方金额总和" placement="right">
							<i class="icon iconfont icon-wenhao"></i> </el-tooltip>：{{ calculateObj.operationalFlowIn }}
					</p>
					<p>
						经营性流入占比(%)<el-tooltip class="item" effect="dark" content="经营性流入占比 = 经营性流入/合计总流入" placement="right">
							<i class="icon iconfont icon-wenhao"></i> </el-tooltip>：{{ calculateObj.operationalFlowInRate }}
					</p>
					<p>
						私转公占比(%)<el-tooltip class="item" effect="dark" content="私转公占比 = 私转公流入/合计总流入" placement="right">
							<i class="icon iconfont icon-wenhao"></i> </el-tooltip>：{{ calculateObj.privateToPublicRate }}
					</p>
					<p>
						统计周期日均余额(元)<el-tooltip class="item" effect="dark" content="统计周期日均余额：统计周期日均余额。算法：统计每一天的余额/统计周期天数"
							placement="right">
							<i class="icon iconfont icon-wenhao"></i> </el-tooltip>：{{ calculateObj.cycleAverageDailyBalance }}
					</p>
					<p>
						统计周期日均余额/日均收入(%)<el-tooltip class="item" effect="dark" content="统计周期日均余额/日均收入=日均余额/（上一年度主营业务收入/365）"
							placement="right">
							<i class="icon iconfont icon-wenhao"></i> </el-tooltip>：
						<span style="color: red" v-if="calculateObj.cycleAverageDailyBalanceRate &&
							calculateObj.cycleAverageDailyBalanceRate
								.length > 10
							">{{
		calculateObj.cycleAverageDailyBalanceRate
	}}</span>
						<span v-else>{{
							calculateObj.cycleAverageDailyBalanceRate
						}}</span>
					</p>
					<p>
						是否有小额贷款公司往来记录<el-tooltip class="item" effect="dark" content="是否有小额贷款公司往来记录:交易对手是否包含小额贷款、小贷的银行流水记录"
							placement="right">
							<i class="icon iconfont icon-wenhao"></i>
						</el-tooltip>
						：{{ calculateObj.loanRecordFlag }}
					</p>
				</div>
				<div>
					<p>流水截止年月：{{ calculateObj.endMonth }}</p>
					<p>流水涉及银行：{{ calculateObj.bankName }}</p>
					<p>
						合计总流出(元)<el-tooltip class="item" effect="dark" content="合计总流出：统计所有借方金额的总和" placement="right">
							<i class="icon iconfont icon-wenhao"></i> </el-tooltip>：{{ calculateObj.totalExpend }}
					</p>
					<p>
						同名划出(元)<el-tooltip class="item" effect="dark" content="同名划出：统计借方交易对手是借款人本人的流水的借方金额总和，交易对手与客户名称同名"
							placement="right">
							<i class="icon iconfont icon-wenhao"></i> </el-tooltip>：{{ calculateObj.sameNameTransferOut }}
					</p>
					<p>
						关联或疑似关联流出(元)<el-tooltip class="item" effect="dark" placement="right">
							<div v-html="'关联或疑似关联流出：统计借方交易对手是借款人法人、股东、关联公司的流水的的借方金额总和，<br/>交易对手与法定代表人同名、交易对手与关键人的姓名同名、交易对手与股东同名、交易对手与关联公司同名'
								" slot="content"></div>
							<i class="icon iconfont icon-wenhao"></i>
						</el-tooltip>：{{ calculateObj.relatedFlowOut }}
					</p>
					<p>
						金融机构流出(元)<el-tooltip class="item" effect="dark" content="金融机构流出：借方交易对手名称含银行、保理、融资租赁、小贷、担保、金融等字样的流水的借方金额总和"
							placement="right">
							<i class="icon iconfont icon-wenhao"></i> </el-tooltip>：{{ calculateObj.financialOrganizationsFlowOut }}
					</p>
					<p>
						公转私流出(元)<el-tooltip class="item" effect="dark"
							content="公转私流出：借方交易对手是个人的流水统计减去摘要显示含工资、报销的流水，首先排除公司关键字，然后判断字符数介于4个字符到8个字符" placement="right">
							<i class="icon iconfont icon-wenhao"></i> </el-tooltip>：{{ calculateObj.publicToPrivateFlowOut }}
					</p>
					<p>
						经营性流出(元)<el-tooltip class="item" effect="dark"
							content="经营性流出 = 合计总流出 - 同名划出 - 关联或疑似关联流出 - 金融机构流出 - 公转私流出 + 借款人法人、股东借方金额总和" placement="right">
							<i class="icon iconfont icon-wenhao"></i> </el-tooltip>：{{ calculateObj.operationalFlowOut }}
					</p>
					<p>
						经营性流出占比(%)<el-tooltip class="item" effect="dark" content="经营性流出占比 = 经营性流出/合计总流出" placement="right">
							<i class="icon iconfont icon-wenhao"></i> </el-tooltip>：{{ calculateObj.operationalFlowOutRate }}
					</p>
					<p>
						公转私占比(%)<el-tooltip class="item" effect="dark" content="公转私占比 = 公转私流出/合计总流出" placement="right">
							<i class="icon iconfont icon-wenhao"></i> </el-tooltip>：{{ calculateObj.publicToPrivateRate }}
					</p>
					<p>
						最近月日均余额(元)<el-tooltip class="item" effect="dark" content="最近月日均余额，算法同周期日均余额，只是取数为最近一个月" placement="right">
							<i class="icon iconfont icon-wenhao"></i> </el-tooltip>：{{ calculateObj.monthAverageDailyBalance }}
					</p>
					<p>
						最近月日均余额/日均收入(%)<el-tooltip class="item" effect="dark" content="最近月日均余额/日均收入=日均余额/（上一年度主营业务收入/365）"
							placement="right">
							<i class="icon iconfont icon-wenhao"></i> </el-tooltip>：{{ calculateObj.monthAverageDailyBalanceRate }}
					</p>
				</div>
			</div>
			<div class="sy-card-header" style="position: relative">
				<i></i>银行流水前十大
				<download-excel class="export-excel-wrapper" :data="jsonData" :fields="jsonFields" :name="excelName"
					:before-generate="fetchExcel" style="position: absolute; right: 0; top: 35px; z-index: 3">
					<el-button type="primary"> 导出所选表格信息 </el-button>
				</download-excel>
			</div>

			<el-tabs v-model="activeName" type="card" @tab-click="handleClick">
				<el-tab-pane label="前10大流入客户" name="0">
					<!-- table 列表 -->
					<el-table :data="flowInClientList" border style="width: 100%">
						<el-table-column align="left" prop="accountName" label="交易对手">
						</el-table-column>
						<el-table-column align="left" prop="lenderAmount" label="金额(元)">
						</el-table-column>
						<el-table-column align="left" prop="proportion" label="占比(%)">
						</el-table-column>
						<el-empty slot="empty" description="没有找到符合条件的数据"></el-empty>
					</el-table>
				</el-tab-pane>
				<el-tab-pane label="前10大流出客户" name="1">
					<el-table :data="flowOutClientList" border style="width: 100%">
						<el-table-column align="left" prop="accountName" label="交易对手">
						</el-table-column>
						<el-table-column align="left" prop="debitAmount" label="金额(元)">
						</el-table-column>
						<el-table-column align="left" prop="proportion" label="占比(%)">
						</el-table-column>
						<el-empty slot="empty" description="没有找到符合条件的数据"></el-empty> </el-table></el-tab-pane>
				<el-tab-pane label="10笔大额流入" name="2">
					<el-table :data="flowInAmountList" border style="width: 100%">
						<el-table-column align="left" prop="transactionDate" label="日期">
						</el-table-column>
						<el-table-column align="left" prop="accountName" label="交易对手">
						</el-table-column>
						<el-table-column align="left" prop="lenderAmount" label="金额(元)">
						</el-table-column>
						<el-table-column align="left" prop="summary" label="摘要">
						</el-table-column>
						<el-empty slot="empty" description="没有找到符合条件的数据"></el-empty>
					</el-table>
				</el-tab-pane>
				<el-tab-pane label="10笔大额流出" name="3"><el-table :data="flowOutAmountList" border style="width: 100%">
						<el-table-column align="left" prop="transactionDate" label="日期">
						</el-table-column>
						<el-table-column align="left" prop="accountName" label="交易对手">
						</el-table-column>
						<el-table-column align="left" prop="debitAmount" label="金额(元)">
						</el-table-column>
						<el-table-column align="left" prop="summary" label="摘要">
						</el-table-column>
						<el-empty slot="empty" description="没有找到符合条件的数据"></el-empty> </el-table></el-tab-pane>
			</el-tabs>
		</div>
		<importDrawer @closeVisible="dialogVisible = false" @closeVisibleSuccess="closeVisibleSuccess"
			:dialogVisible.sync="dialogVisible" :parentRuleForm="ruleForm" />
		<v-tour :options="myOptions" name="myTour1" :steps="steps"></v-tour>
	</div>
</template>

<script>
import qs from 'qs';

import importDrawer from './model/importDrawer.vue';
import {
	bankFlowList,
	calculateBankStatement,
	clearBankFlow,
	deleteRecord,
	flowInClientList,
	flowOutClientList,
	flowInAmountList,
	flowOutAmountList,
	getUserNameList,
} from '@/api/index.js';
const jsonFieldsList = [
	{
		'交易对手 ': 'accountName',
		'金额(元)': 'lenderAmount',
		'占比(%)': 'proportion',
	},
	{
		'交易对手 ': 'accountName',
		'金额(元)': 'debitAmount',
		'占比(%)': 'proportion',
	},
	{
		'日期 ': 'transactionDate',
		'交易对手 ': 'accountName',
		'金额(元)': 'lenderAmount',
		'摘要 ': 'summary',
	},
	{
		'日期 ': 'transactionDate',
		'交易对手 ': 'accountName',
		'金额(元)': 'debitAmount',
		'摘要 ': 'summary',
	},
];
const arr = [
	'flowInClientList',
	'flowOutClientList',
	'flowInAmountList',
	'flowOutAmountList',
];
export default {
	name: 'dataanalysis',
	data() {
		return {
			select: '',
			activeName: '0',
			calculateObj: {},
			billData: [],
			dialogVisible: false,
			currentPage: 1,
			ProductCategory: [],
			ruleForm: {
				clientName: '',
				transactionBank: '',
				legalPerson: '',
				mainBusinessIncomeLastYear: '',
				pageSize: 10,
				pageNo: 1,
				total: 0,
				shareholder: [{ value: '' }],
				companyName: [{ value: '' }],
				personName: [{ value: '' }],
				cooperationPresidentId:
					this.$store.state.userInfo.userVo.cooperationPresidentId,
			},
			flowInClientList: [],
			flowOutClientList: [],
			flowInAmountList: [],
			flowOutAmountList: [],
			jsonData: [],
			jsonFields: {},
			excelName: '前10大流入客户.xls',
			restaurants: [],
			steps: [
				{
					target: '#v-step-01',
					content: `下载所需的数据模板`,
				},
				{
					target: '#v-step-11',
					content: '补充对应的客户名称',
				},
				{
					target: '#v-step-22',
					content: '点击导入按钮',
				},
				{
					target: '#v-step-33',
					content:
						'导入成功后,点击自动计算财务指标数据，就可以得到财务指标数据',
					params: {
						placement: 'top',
					},
				},
			],
			myOptions: {
				useKeyboardNavigation: false, //不使用←、→和ESC键来导航tour
				startTimeout: 1000, //1秒后执行
				labels: {
					buttonSkip: '跳过',
					buttonPrevious: '上一步',
					buttonNext: '下一步',
					buttonStop: '关闭',
				},
			},
		};
	},
	created() {
		// this.getBankFlowList();
		// this.getflowInClientList();
		// this.getflowOutClientList();
		// this.getflowInAmountList();
		// this.getflowOutAmountList();
	},
	mounted() {
		this.getUserNameList();
		this.$nextTick(() => {
			if (!localStorage.getItem('B')) {
				localStorage.setItem('B', 1);
				this.$tours['myTour1'].start();
			}
		});
	},
	methods: {
		handleCommand(val) {
			location.href =
				this.$baseUrl +
				'api/fm/downloadBankFlowTemplate.do?templateType=' +
				val;
		},
		async getUserNameList() {
			const res = await getUserNameList({
				cooperationPresidentId: this.ruleForm.cooperationPresidentId,
			});
			this.restaurants = res.map((item) => ({ value: item }));
		},
		setValueNull() {
			this.$Debounce(this.getTableListData);
		},
		getTableListData() {
			this.getBankFlowList();
			this.getflowInClientList();
			this.getflowOutClientList();
			this.getflowInAmountList();
			this.getflowOutAmountList();
		},
		exportBankFlowData() {
			this.$refs.ruleForm.validate(async (valid) => {
				if (valid) {
					let obj = JSON.parse(JSON.stringify(this.calculateObj));
					if (JSON.stringify(obj) == '{}') {
						this.$message({
							message: '暂无数据',
							type: 'warning',
						});
						return;
					}
					obj.clientName = this.ruleForm.clientName;
					if (obj.cycleAverageDailyBalanceRate.length > 10) {
						obj.cycleAverageDailyBalanceRate = '';
					}
					location.href =
						this.$baseUrl +
						'api/fm/exportBankFlowData.do?' +
						qs.stringify(obj);
				} else {
					this.$message({
						message: '请输入客户名称',
						type: 'warning',
					});
					console.log('error submit!!');
					return false;
				}
			});
		},
		submitForm(formName, name) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this[name] = true;
				} else {
					this.$confirm('请输入客户名称', '提示', {
						confirmButtonText: '确定',
						type: 'warning',
					});
					console.log('error submit!!');
					return false;
				}
			});
		},
		addFactor(index, name) {
			if (!index) {
				this.ruleForm[name].push({
					value: '',
				});
			} else {
				this.ruleForm[name].splice(index, 1);
			}
		},
		delRowData(data) {
			this.$confirm('您确定要删除银行流水吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(async () => {
					const res = await deleteRecord({
						bankFlowId: data.bankFlowId,
					});
					if (res.returncode == 0) {
						this.$message({
							message: '删除成功！',
							type: 'success',
						});
					}
					this.getBankFlowList();
				})
				.catch(() => { });
		},
		getCalculateBankStatement() {
			if (!this.billData.length) {
				this.$confirm('没有找到可分析的银行流水！', '提示', {
					confirmButtonText: '确定',
					type: 'warning',
				});
				return;
			} else {
				this.$refs.ruleForm.validate(async (valid) => {
					if (valid) {
						const obj = {};
						obj.clientName = this.ruleForm.clientName;
						obj.legalPerson = this.ruleForm.legalPerson;
						obj.mainBusinessIncomeLastYear =
							this.ruleForm.mainBusinessIncomeLastYear;
						obj.shareholder = this.ruleForm.shareholder.map(
							(item) => {
								return item.value;
							}
						);
						obj.companyName = this.ruleForm.companyName.map(
							(item) => {
								return item.value;
							}
						);
						obj.personName = this.ruleForm.personName.map(
							(item) => {
								return item.value;
							}
						);

						const res = await calculateBankStatement(obj);
						if (!res.returncode) {
							this.calculateObj = res;
							this.calculateObj.cycleAverageDailyBalanceRate =
								res.cycleAverageDailyBalanceRate ||
								'上一年度主营业务收入未录入，无法计算得到对应的值';

							this.$confirm('计算银行流水分析成功！', '提示', {
								confirmButtonText: '确定',
								type: 'success',
								showCancelButton: false,
							});
						}
					} else {
						this.$confirm('请输入客户名称', '提示', {
							confirmButtonText: '确定',
							type: 'warning',
						});
						console.log('error submit!!');
						return false;
					}
				});
			}
		},
		closeVisibleSuccess() {
			this.getUserNameList();

			this.getBankFlowList();
			this.getflowInClientList();
			this.getflowOutClientList();
			this.getflowInAmountList();
			this.getflowOutAmountList();
			this.dialogVisible = false;
		},
		clearData() {
			this.$confirm('确定清空当前客户的银行流水数据？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(async () => {
					let obj = {};
					if (this.ruleForm.clientName) {
						obj.clientName = this.ruleForm.clientName;
					}
					await clearBankFlow(obj);
					this.getUserNameList();
					this.ruleForm.clientName = '';
					this.billData = [];
					this.flowInClientList = [];
					this.flowOutClientList = [];
					this.flowInAmountList = [];
					this.flowOutAmountList = [];
					this.calculateObj = {};
				})
				.catch(() => { });
		},
		async getBankFlowList() {
			const res = await bankFlowList(this.ruleForm);
			this.billData = res.list;
			this.ruleForm.total = res.totalRows;
		},
		handleSizeChange(val) {
			this.ruleForm.pageSize = val;
			this.getBankFlowList();
		},
		handleCurrentChange(val) {
			this.ruleForm.pageNo = val;
			this.getBankFlowList();
		},
		async getflowInClientList() {
			const res = await flowInClientList(this.ruleForm);
			this.flowInClientList = res.list;
		},
		async getflowOutClientList() {
			const res = await flowOutClientList(this.ruleForm);
			this.flowOutClientList = res.list;
		},
		async getflowInAmountList() {
			const res = await flowInAmountList(this.ruleForm);
			this.flowInAmountList = res.list;
		},

		async getflowOutAmountList() {
			const res = await flowOutAmountList(this.ruleForm);
			this.flowOutAmountList = res.list;
		},
		handleClick(tab, event) {
			this.excelName = tab.label + '.xls';
		},
		fetchExcel() {
			console.log(11232)
			this.jsonData = this[arr[this.activeName]];
			this.jsonFields = jsonFieldsList[this.activeName];
		},
		querySearch(queryString, cb) {
			cb(this.restaurants);
		},
		handleSelect(item) {
			console.log(item);
		},
	},
	components: {
		importDrawer,
	},
};
</script>
<style lang="scss" scoped>
.dataanalysis {
	width: 100%;
	height: calc(100vh - 150px);
	overflow: auto;
}

.fy {
	text-align: end;
	padding: 10px 20px;
}

.sy-card-header {
	height: 42px;
	line-height: 42px;
	border-bottom: 1px solid #f6f6f6;
	font-size: 14px;
	font-weight: bold;
}

.sy-card-header i {
	width: 3px;
	height: 14px;
	background: #5799f0;
	display: inline-block;
	vertical-align: middle;
	margin-top: -2px;
	margin-right: 5px;
}

.file-list {
	color: #009688;
	position: relative;
	border: 1px solid #e6e6e6;
	box-sizing: border-box;
	padding: 10px;
	margin: 10px 0;

	>p {
		position: absolute;
		top: -7px;
		font-size: 12px;
		left: 16px;
		background: #fff;
		padding: 0 5px;
	}
}

.content-box {
	padding: 0 10px;
	display: flex;

	div {
		flex: 1;
	}

	p {
		color: #888;
		margin: 20px 10px;
	}
}

::v-deep .el-tabs__header {
	// margin: 0;
}

.mb {
	margin-bottom: 10px;
}

.el-icon-circle-plus-outline {
	color: #409eff;
}

.el-icon-remove-outline {
	color: red;
}

.search-list>div {
	display: flex;
	flex: 1;
	flex-wrap: wrap;
	width: 100%;
}

::v-deep .add-list>div {
	width: 100%;
	box-sizing: border-box;
}

.add-list {
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
}

::v-deep .add-list label {
	opacity: 0;
}

::v-deep .add-list>div:first-child label {
	opacity: 1;
}

::v-deep .sy-card-header {
	border-bottom: none;
}

::v-deep .search-list .el-form-item>div {
	margin-left: 0 !important;
	flex: 1;
}
</style>
